<template>
    <svg viewBox="0 0 2052 2054" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_136)">
            <ellipse cx="1075.26" cy="983.732" rx="352.738" ry="363.732" fill="#F9A7FC" />
        </g>
        <g filter="url(#filter1_f_1002_136)">
            <ellipse cx="842.838" cy="870.199" rx="222.398" ry="229.501" fill="#FD91AB" />
        </g>
        <g filter="url(#filter2_f_1002_136)">
            <ellipse cx="1075.57" cy="1077.79" rx="165.887" ry="166.191" fill="#0028F9" />
        </g>
        <g filter="url(#filter3_f_1002_136)">
            <path
                d="M894.375 1093.35C751.267 999.226 821.234 625.501 741.673 746.915C699.663 811.024 552.097 963.964 514.66 1093.35C481.196 1209 550.933 1302.72 618.477 1347.14C761.585 1441.27 992.51 1373.53 1072.07 1252.12C1151.63 1130.7 1037.48 1187.47 894.375 1093.35Z"
                fill="#FFCC80"
                fill-opacity="0.6"
            />
        </g>
        <defs>
            <filter id="filter0_f_1002_136" x="102.523" y="0" width="1945.48" height="1967.46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="310" result="effect1_foregroundBlur_1002_136" />
            </filter>
            <filter id="filter1_f_1002_136" x="0.439453" y="20.6978" width="1684.8" height="1699" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="310" result="effect1_foregroundBlur_1002_136" />
            </filter>
            <filter id="filter2_f_1002_136" x="99.6787" y="101.595" width="1951.77" height="1952.38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="405" result="effect1_foregroundBlur_1002_136" />
            </filter>
            <filter id="filter3_f_1002_136" x="86.0049" y="302.901" width="1432.23" height="1508.44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="210" result="effect1_foregroundBlur_1002_136" />
            </filter>
        </defs>
    </svg>
</template>
