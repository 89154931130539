import { logUserInteraction } from "~vue/utils/logUtils";
import { reactive } from "vue";
import { useStorage } from "vue3-storage";

const storage = useStorage();

export const COACHING_MODES = {
    GENERAL: "General Coach",
    CUSTOM: "Custom Coach",
    GOAL_SETTING: "Goal Setting Coach",
    REFLECTIVE: "Reflective Coach",
    ROLE_PLAY: "Role Play Coach",
    SKILL_BUILDING: "Skill Building Coach",
    TASK: "Task Coach",
};

const COACHING_MODES_LIST = Object.values(COACHING_MODES);

export const useChatStore = reactive({
    dictationOn: storage.getStorageSync("dictation_on") ?? false,
    toggleDictation() {
        logUserInteraction(this.dictationOn ? "turned_dictation_off" : "turned_dictation_on");
        storage.setStorageSync("dictation_on", !this.dictationOn);
        this.dictationOn = !this.dictationOn;
    },
    coachingMode: COACHING_MODES.GENERAL,
    setCoachingMode(mode) {
        if (COACHING_MODES_LIST.includes(mode)) this.coachingMode = mode;
    },
    systemMessageAnimating: false,
    setSystemMessageAnimating(isAnimating) {
        this.systemMessageAnimating = isAnimating;
    },
    hasTeamMemberContext: false,
    setHasTeamMemberContext(hasContext) {
        this.hasTeamMemberContext = hasContext;
    },
    files: [],
    addFile({ name, content, description }) {
        this.files.push({ name, content, description });
    },
    removeFile(name) {
        this.files = this.files.filter((file) => file.name !== name);
    },
    removeAllFiles() {
        this.files = [];
    },
});
