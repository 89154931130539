<template>
    <svg viewBox="0 0 1890 1896" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_145)">
            <ellipse cx="749.822" cy="1015.68" rx="329.822" ry="313.319" fill="#31BACD" />
        </g>
        <g filter="url(#filter1_f_1002_145)">
            <ellipse cx="734.554" cy="743.364" rx="314.554" ry="249.364" fill="#A2EE26" fill-opacity="0.7" />
        </g>
        <g filter="url(#filter2_f_1002_145)">
            <ellipse cx="953.186" cy="948.15" rx="336.813" ry="347.389" fill="#70E59F" fill-opacity="0.9" />
        </g>
        <defs>
            <filter id="filter0_f_1002_145" x="0" y="282.361" width="1499.64" height="1466.64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="210" result="effect1_foregroundBlur_1002_145" />
            </filter>
            <filter id="filter1_f_1002_145" x="0" y="74" width="1469.11" height="1338.73" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="210" result="effect1_foregroundBlur_1002_145" />
            </filter>
            <filter id="filter2_f_1002_145" x="16.373" y="0.760742" width="1873.63" height="1894.78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1002_145" />
            </filter>
        </defs>
    </svg>
</template>
