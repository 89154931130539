<template>
    <svg viewBox="0 0 2160 2138" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_141)">
            <ellipse cx="1019.38" cy="1019.02" rx="395.382" ry="395.025" fill="#40BAFF" />
        </g>
        <g filter="url(#filter1_f_1002_141)">
            <ellipse cx="1121.86" cy="1196.35" rx="317.94" ry="317.652" fill="#5057FF" fill-opacity="0.8" />
        </g>
        <g filter="url(#filter2_f_1002_141)">
            <ellipse cx="1288.2" cy="930.766" rx="247.797" ry="247.573" fill="#B6A4FF" fill-opacity="0.8" />
        </g>
        <defs>
            <filter id="filter0_f_1002_141" x="0.400024" y="0.400024" width="2037.97" height="2037.25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="311.8" result="effect1_foregroundBlur_1002_141" />
            </filter>
            <filter id="filter1_f_1002_141" x="180.324" y="255.095" width="1883.08" height="1882.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="311.8" result="effect1_foregroundBlur_1002_141" />
            </filter>
            <filter id="filter2_f_1002_141" x="416.806" y="59.5927" width="1742.79" height="1742.35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="311.8" result="effect1_foregroundBlur_1002_141" />
            </filter>
        </defs>
    </svg>
</template>
