<template>
    <svg viewBox="0 0 2326 2240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_119)">
            <ellipse cx="1123.41" cy="967.998" rx="395.189" ry="384.998" fill="#80EBB8" fill-opacity="0.5" />
        </g>
        <g filter="url(#filter1_f_1002_119)">
            <ellipse cx="1163.19" cy="984.303" rx="395.189" ry="384.998" fill="#80EBB1" fill-opacity="0.5" />
        </g>
        <g filter="url(#filter2_f_1002_119)">
            <ellipse cx="1037.15" cy="1081.82" rx="327.148" ry="327.781" fill="url(#paint0_linear_1002_119)" />
        </g>
        <g filter="url(#filter3_f_1002_119)">
            <ellipse
                cx="412.085"
                cy="369.289"
                rx="412.085"
                ry="369.289"
                transform="matrix(0.999774 -0.0212526 0.0211707 0.999776 743.381 759.587)"
                fill="url(#paint1_linear_1002_119)"
            />
        </g>
        <defs>
            <filter id="filter0_f_1002_119" x="294.467" y="149.243" width="1657.89" height="1637.51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="216.879" result="effect1_foregroundBlur_1002_119" />
            </filter>
            <filter id="filter1_f_1002_119" x="334.243" y="165.548" width="1657.89" height="1637.51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="216.879" result="effect1_foregroundBlur_1002_119" />
            </filter>
            <filter id="filter2_f_1002_119" x="276.243" y="320.285" width="1521.81" height="1523.08" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="216.879" result="effect1_foregroundBlur_1002_119" />
            </filter>
            <filter id="filter3_f_1002_119" x="0.653076" y="0.253662" width="2325.07" height="2239.56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="375.235" result="effect1_foregroundBlur_1002_119" />
            </filter>
            <linearGradient id="paint0_linear_1002_119" x1="804.146" y1="989.897" x2="1489.48" y2="1231.57" gradientUnits="userSpaceOnUse">
                <stop offset="0.269017" stop-color="#93A2F1" />
                <stop offset="0.928018" stop-color="#F093F1" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_1002_119" x1="635.764" y1="264.303" x2="119.882" y2="252.223" gradientUnits="userSpaceOnUse">
                <stop offset="0.386557" stop-color="#81C5F9" />
                <stop offset="0.859921" stop-color="#81C5F9" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
