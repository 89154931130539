<template>
    <component :is="CoachingModeMarble" :class="[isLoading && `loadingMarble ${sizeClass}`]"></component>
</template>

<script setup>
import CustomCoachMarble from "~vue/components/navigation/CoachingModeMarbles/CustomCoachMarble.vue";
import GeneralCoachMarble from "~vue/components/navigation/CoachingModeMarbles/GeneralCoachMarble.vue";
import GoalSettingCoachMarble from "~vue/components/navigation/CoachingModeMarbles/GoalSettingCoachMarble.vue";
import ReflectiveCoachMarble from "~vue/components/navigation/CoachingModeMarbles/ReflectiveCoachMarble.vue";
import RolePlayCoachMarble from "~vue/components/navigation/CoachingModeMarbles/RolePlayCoachMarble.vue";
import SkillBuildingCoachMarble from "~vue/components/navigation/CoachingModeMarbles/SkillBuildingCoachMarble.vue";
import TaskCoachMarble from "~vue/components/navigation/CoachingModeMarbles/TaskCoachMarble.vue";
import { COACHING_MODES, useChatStore } from "~vue/stores/chatStore";
import { computed } from "vue";

const props = defineProps({
    size: {
        type: String,
        default: "base",
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
});

const sizeClass = computed(() => {
    const sizes = {
        base: "w-16 h-16",
        big: "w-28 h-28",
    };

    if (Object.hasOwn(sizes, props.size)) {
        return sizes[props.size];
    }

    return sizes.base;
});

const CoachingModeMarble = computed(() => {
    switch (useChatStore.coachingMode) {
        case COACHING_MODES.GENERAL:
            return GeneralCoachMarble;
        case COACHING_MODES.CUSTOM:
            return CustomCoachMarble;
        case COACHING_MODES.GOAL_SETTING:
            return GoalSettingCoachMarble;
        case COACHING_MODES.REFLECTIVE:
            return ReflectiveCoachMarble;
        case COACHING_MODES.ROLE_PLAY:
            return RolePlayCoachMarble;
        case COACHING_MODES.SKILL_BUILDING:
            return SkillBuildingCoachMarble;
        case COACHING_MODES.TASK:
            return TaskCoachMarble;
        default:
            return GeneralCoachMarble;
    }
});
</script>

<style scoped>
.loadingMarble {
    animation: spinningMarble 2s cubic-bezier(0.65, 0, 0.25, 1.1) infinite;
    animation-delay: 300ms;
}

@keyframes spinningMarble {
    100% {
        transform: rotate(360deg);
    }
}
</style>
