<template>
    <svg viewBox="0 0 1762 1785" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1002_124)">
            <ellipse cx="967.966" cy="717.122" rx="338.314" ry="347.122" fill="#FF7CAB" fill-opacity="0.6" />
        </g>
        <g filter="url(#filter1_f_1002_124)">
            <ellipse
                cx="327.589"
                cy="211.077"
                rx="327.589"
                ry="211.077"
                transform="matrix(0.784294 -0.62039 0.620144 0.784488 716.352 1029.83)"
                fill="url(#paint0_linear_1002_124)"
                fill-opacity="0.6"
            />
        </g>
        <g filter="url(#filter2_f_1002_124)">
            <path
                d="M1147.87 1014.99C1147.87 1179.17 995.87 1248.95 834.715 1248.95C673.561 1248.95 536 1105.42 536 941.245C536 777.067 632.95 596.215 794.105 596.215C884.417 596.215 817.591 840.516 871.115 906.161C913.099 957.653 1147.87 942.816 1147.87 1014.99Z"
                fill="#EA4BC7"
            />
        </g>
        <defs>
            <filter id="filter0_f_1002_124" x="260.548" y="0.895782" width="1414.84" height="1432.45" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="184.552" result="effect1_foregroundBlur_1002_124" />
            </filter>
            <filter id="filter1_f_1002_124" x="446.661" y="360.918" width="1315.03" height="1262.52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="184.552" result="effect1_foregroundBlur_1002_124" />
            </filter>
            <filter id="filter2_f_1002_124" x="0.798889" y="61.0142" width="1682.27" height="1723.13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="267.601" result="effect1_foregroundBlur_1002_124" />
            </filter>
            <linearGradient id="paint0_linear_1002_124" x1="333.24" y1="71.2874" x2="329.895" y2="422.175" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF6AA0" stop-opacity="0" />
                <stop offset="0.606955" stop-color="#FF6AA0" />
            </linearGradient>
        </defs>
    </svg>
</template>
